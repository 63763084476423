import BaseAPI from "./base";

const dedicationNumberAPI = {
  getList(payload) {
    return BaseAPI("post", `/DNumber/QueryDedicationNumber`, payload);
  },
  addDedicationNumber(payload) {
    return BaseAPI("post", `/DNumber/AddDedicationNumber`, payload);
  },
  modifyDedicationNumber(payload) {
    return BaseAPI("put", `/DNumber/ModifyDedicationNumber`, payload);
  },
  changeDedicationNumber(payload) {
    return BaseAPI("put", `/DNumber/ChangeDedicationNumber`, payload);
  },
};

export default {
  dedicationNumberAPI,
};
