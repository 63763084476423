import BaseAPI from "./base";

const accountAPI = {
  getAdminPageList(payload) {
    return BaseAPI("get", `/Admin/SelectAdminPageList`, payload);
  },
  getAdminChurchList(payload) {
    return BaseAPI("get", `/Admin/SelectAdminChurch`, payload);
  },
  getAdminOperateList(payload) {
    return BaseAPI("get", `/Admin/SelectAdminOperateList`, payload);
  },
  getResetPassword(payload) {
    return BaseAPI("get", `/Admin/GetResetPassword`, payload);
  },
  getList(payload) {
    return BaseAPI("post", `/Admin/QueryAdminInfo`, payload);
  },
  getMemberName(payload) {
    return BaseAPI("post", `/Admin/SelectMemberName`, payload);
  },
  addAccount(payload) {
    return BaseAPI("post", `/Admin/AddMemberInfo`, payload);
  },
  modifyAccount(payload) {
    return BaseAPI("post", `/Admin/ModifyAdmin`, payload);
  },
  deleteAccount(payload) {
    return BaseAPI("post", `/Admin/DeleteAdmin`, payload);
  },
  modifyEnable(payload) {
    return BaseAPI("post", `/Admin/ModifyEnable`, payload);
  },
};

export default {
  accountAPI,
};
