import BaseAPI from "./base";

const dedicationAmountAPI = {
  getList(payload) {
    return BaseAPI("post", `/Dedication/QueryDedication`, payload);
  },
  getDedicationName(payload) {
    return BaseAPI("post", `/Dedication/GetDedicationName`, payload);
  },
  addDedication(payload) {
    return BaseAPI("post", `/Dedication/AddDedication`, payload);
  },
  modifyDedication(payload) {
    return BaseAPI("put", `/Dedication/ModifyDedication`, payload);
  },
  deleteDedication(payload) {
    return BaseAPI("delete", `/Dedication/DeleteDedication`, payload);
  },
  exportData(payload) {
    return BaseAPI("post", `/Dedication/ExportData`, payload);
  },
};

export default {
  dedicationAmountAPI,
};
