import auth from "./auth";
import common from "./common";
import dedicationNumber from "./dedicationNumber";
import dedicationAmount from "./dedicationAmount";
import account from "./account";

export default {
  auth,
  common,
  dedicationNumber,
  dedicationAmount,
  account,
};
