const isPay = (val) => {
  switch (val) {
    case 0:
      return '未繳費'
    case 1:
      return '已繳費'
    default:
      return '-'
  }
}

export default {
  isPay
}