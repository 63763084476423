import BaseAPI from "./base";

const commonAPI = {
  getClcList(data) {
    return BaseAPI("get", `/ClcForm/SelectChurch`, data);
  },
  getClcGroupList(data) {
    return BaseAPI("get", `/ClcForm/SelectGroup`, data);
  },

  getDedicationType(data) {
    return BaseAPI("get", `/ClcForm/SelectDedicationType`, data);
  },
  getDedicationWay(data) {
    return BaseAPI("get", `/ClcForm/SelectDedicationWay`, data);
  },
  getDedicationGroup(data) {
    return BaseAPI("get", `/ClcForm/SelectDedicationGroup`, data);
  },
  getDedicationUniformNo(data) {
    return BaseAPI("get", `/ClcForm/SelectUniformNo`, data);
  },
  getLastUser(data) {
    return BaseAPI("get", `/ClcForm/SelectLastUser`, data);
  },
};

export default {
  commonAPI,
};
