import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Auth.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: () => import("../pages/auth/login.vue"),
      },
    ],
  },
  {
    path: "/main",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/dedicationNumber",
        name: "DedicationNumber",
        component: () =>
          import("../pages/dedicationNumber/dedicationNumberSearch.vue"),
      },
      {
        path: "/dedicationAmount",
        name: "DedicationAmount",
        component: () =>
          import("../pages/dedicationAmount/dedicationAmountSearch.vue"),
      },
      {
        path: "/account",
        name: "Account",
        component: () => import("../pages/account/accountSearch.vue"),
      },
      {
        path: "/permissions",
        name: "Permissions",
        component: () => import("../pages/misc/permissions.vue"),
      },
      {
        path: "/*",
        name: "*",
        component: () => import("../pages/misc/progressing.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
