import BaseAPI from "./base";

const auth = {
  login(params) {
    return BaseAPI("post", "/Auth/Login", params);
  },
  // logout() { return BaseAPI('get', 'Auth/logout' ) }
  getPageList(params) {
    return BaseAPI("get", "/Admin/GetPageList", params);
  },
};

export default auth;
