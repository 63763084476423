const auth = {
  namespaced: true,
  state: {
    user: "",
    token: "",
    isLogin: false,
    right: []
  },
  mutations: {
    SET_AUTH(state, options) {
      state.token = options.token;
      state.user = options.user;
      state.isLogin = options.isLogin;
    },
    SET_INFO(state, options) {
      state.user = options.user;
    },
    SET_RIGHT(state, options) {
      state.right = options.right;
    },
  },
  actions: {
    setAuth(context, options) {
      context.commit("SET_AUTH", {
        token: options.token,
        user: options.user,
        isLogin: options.isLogin,
      });
    },
    setInfo(context, options) {
      context.commit("SET_INFO", {
        user: options.user,
      });
    },
    setRight(context, options) {
      context.commit("SET_RIGHT", {
        right: options.right,
      });
    },
  },
};
export default auth;
